import React from 'react';
import { useRouter } from 'next/router';
import { getLoanSelectionFromQueryParams } from 'lib/query-params/helper';
import { DEFAULT_SELECTION_VALUES } from 'lib/offers/filters';
import { ABTestKeys, Variation } from 'lib/optimizely/flagConfigs';
import { useDecision } from 'lib/optimizely';

export enum InputNames {
  Category = 'category',
  VehiclePrice = 'vehiclePrice',
  InitialPayment = 'initialPayment',
  Amount = 'amount',
  Duration = 'duration',
}

export type LoanSelectionProps = {
  [InputNames.Amount]: number;
  [InputNames.Duration]: number;
  [InputNames.Category]: number;
  [InputNames.VehiclePrice]: number;
  [InputNames.InitialPayment]: number;
};

type LoanSelectionContextProps = {
  loanSelection: LoanSelectionProps;
  setLoanSelection: (props: LoanSelectionProps) => void;
};

/**
 * Create the context itself that is used within the
 * consumer and provider
 */
const LoanSelectionContext = React.createContext<LoanSelectionContextProps>({
  loanSelection: DEFAULT_SELECTION_VALUES,
  setLoanSelection: () => {},
});

/**
 * The provider acts a wrapper to provide the context
 * to its children
 */
function LoanSelectionProvider({ children }: { children: React.ReactNode }) {
  const router = useRouter();
  const loanSelectionFromQueryParams = getLoanSelectionFromQueryParams(
    router.query,
  );

  const [decision] = useDecision(ABTestKeys.CAT3220_Loan_duration_ABC);

  const variation = decision?.variationKey || Variation.CONTROL;

  const variationMap: { [key: string]: { duration?: number; amount?: number } } = {
    [Variation.VARIATION_B]: { duration: 96 },
    [Variation.VARIATION_C]: { amount: 25000 },
  };

  const testVariationObject = variationMap[variation] || {};

  const [loanSelection, setPureLoanSelection] = React.useState(
    {...loanSelectionFromQueryParams, ...testVariationObject},
  );

  function setLoanSelection(props: LoanSelectionProps) {
    setPureLoanSelection(props);
    router.push(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          ...props,
        },
      },
      undefined,
      { shallow: true },
    );
  }

  // Context values passed to consumer and exposed as getter and setter
  const value: LoanSelectionContextProps = {
    loanSelection,
    setLoanSelection,
  };

  return (
    <LoanSelectionContext.Provider value={value}>
      {children}
    </LoanSelectionContext.Provider>
  );
}

/**
 * A convenience wrapper for the LoanSelectionConsumer
 */
const LoanSelectionConsumer = LoanSelectionContext.Consumer;

/**
 * useLoanSelection Hook for easy use within functional components
 */
function useLoanSelection() {
  const context = React.useContext(LoanSelectionContext);
  return context;
}

export { LoanSelectionProvider, LoanSelectionConsumer, useLoanSelection };
